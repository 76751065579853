import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact"
import Product from "../components/product-section"

const ProductPage = () => (
  <Layout>
    <SEO title="About Us" />
    <Product />
    <Contact />
  </Layout>
)

export default ProductPage
