import React from "react"
import styled from "styled-components"
import { AboutHeading } from "./about-section"
import LogoImage from "./img/brands-img"

const ProductContainer = styled.div`
  width: 100%;
  background: #305244;
  padding: 0 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  @media screen and (min-width: 450px) {
    padding: 0 0 16px;
  }
  @media screen and (min-width: 500px) {
    padding: 0 0 16px;
  }
  @media screen and (min-width: 650px) {
    padding: 0 0 32px;
  }
  @media screen and (min-width: 800px) {
    padding: 0 0 32px;
  }
  @media screen and (min-width: 950px) {
    padding: 0 0 48px;
  }
  @media screen and (min-width: 1050px) {
    padding: 0 0 48px;
  }
`

const BrandsContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    color: #fdfdfd;
    width: 100%;
    font-family: "Source Sans Pro";
    text-align: left;
    font-weight: 900;
    margin-top: 0;
  }
  @media screen and (min-width: 450px) {
    padding: 32px;
  }
  @media screen and (min-width: 500px) {
    padding: 32px 48px;
  }
  @media screen and (min-width: 650px) {
    padding: 32px 64px;
  }
  @media screen and (min-width: 700px) {
    padding: 0 64px 32px;
  }
  @media screen and (min-width: 800px) {
    padding: 0 72px 48px;
  }
  @media screen and (min-width: 950px) {
    padding: 0 80px 48px;
  }
  @media screen and (min-width: 1050px) {
    padding: 0 96px 48px;
  }
`

const BrandShapeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
`

const BrandShape = styled.div`
  box-sizing: border-box;
  background: #fdfdfd;
  margin-top: 16px;
  border-radius: 20px;
  padding: 16px;
  width: 40vw;
  height: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  div {
    width: 100%;
    /* height: max-content; */
    img {
      z-index: 0;
    }
  }
  @media screen and (min-width: 450px) {
    width: 35vw;
    height: 35vw;
  }
  @media screen and (min-width: 600px) {
    margin-top: 32px;
    width: 35vw;
    height: 35vw;
  }
  @media screen and (min-width: 750px) {
    width: 30vw;
    height: 30vw;
  }
  @media screen and (min-width: 900px) {
    width: 23vw;
    height: 23vw;
  }
  @media screen and (min-width: 1050px) {
    width: 18vw;
    height: 18vw;
  }
`

const Product = () => (
  <ProductContainer>
    <AboutHeading>
      <h1>Brands of King Alton</h1>
      <svg
        id="Wave"
        viewBox="0 0 1440 169"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-393 169H1890C1890 169 1655.59 34.7134 1467.43 8.99321C1221.03 -24.6869 1099.07 46.785 847.021 50.8642C530.063 55.9938 277.925 -34.5074 -27.4034 19.3247C-225.167 54.1922 -393 169 -393 169Z"
          fill="#305244"
        />
      </svg>
    </AboutHeading>
    {/* 1 */}
    <BrandsContainer>
      <h4>Brands we carry in Batangas and Mindoro</h4>
      <BrandShapeContainer>
        <BrandShape>
          <LogoImage alt="Mega Sardines" filename="logos/mega-sardines.jpg" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Mega Tuna" filename="logos/mega-tuna.jpg" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Mega Prime" filename="logos/mega-prime.jpg" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Mega Primo" filename="logos/primo.jpg" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Fonterra" filename="logos/fonterra.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Mother's Best" filename="logos/mothersbest.jpg" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Nivea" filename="logos/nivea.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Gatsby" filename="logos/gatsby.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Ovaltine" filename="logos/ovaltine.jpg" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Pringles" filename="logos/pringles.jpg" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Ideal" filename="logos/ideal.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Sunmac" filename="logos/sunmac.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Ellie" filename="logos/ellie.jpg" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Milkimoo" filename="logos/milkimoo.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Quaker Oats" filename="logos/quaker.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Fungisol" filename="logos/fungisol.jpg" />
        </BrandShape>
      </BrandShapeContainer>
    </BrandsContainer>
    <BrandsContainer>
      <h4>Brands we carry in Batangas only</h4>
      <BrandShapeContainer>
        <BrandShape>
          <LogoImage alt="Croley" filename="logos/croley.jpg" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Butter Cream" filename="logos/buttercream.jpg" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Lobo" filename="logos/lobo.jpg" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Sunflower Crackers" filename="logos/sunflower.jpg" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Goya" filename="logos/goya.jpg" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Delfi" filename="logos/delfi.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Knick Knacks" filename="logos/kk.jpg" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Push pop" filename="logos/pushpop.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Tootsie" filename="logos/tootsie.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Chips Delight" filename="logos/chips-delight.jpg" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Smileys" filename="logos/smileys.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Bite Block" filename="logos/bite-block.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Cradle" filename="logos/cradle.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Cycles" filename="logos/cycles.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Smart Steps" filename="logos/smart-steps.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="COV-X" filename="logos/covx.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Eskulin" filename="logos/eskulin.jpg" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Lola Remedios" filename="logos/lola-remedios.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Uni" filename="logos/uni.JPG" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Beach Hut" filename="logos/beach-hut.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Ellips" filename="logos/ellips.png" />
        </BrandShape>
      </BrandShapeContainer>
    </BrandsContainer>
    <BrandsContainer>
      <h4>Brands we carry in Mindoro only</h4>
      <BrandShapeContainer>
        <BrandShape>
          <LogoImage alt="CDO" filename="logos/cdo.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="GreenCross" filename="logos/greencross.jpg" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Del" filename="logos/del.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Zonrox" filename="logos/zonrox.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Hobe" filename="logos/hobe.png" />
        </BrandShape>
        <BrandShape>
          <LogoImage alt="Lewis and Pearl" filename="logos/lewis-pearl.png" />
        </BrandShape>
      </BrandShapeContainer>
    </BrandsContainer>
  </ProductContainer>
)

export default Product
